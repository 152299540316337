import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { Navigate } from 'react-router-dom';
import Header from './Header';
import { logout } from '../redux/authSlice';

const AppContainer = ({ children }: any) => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  if (!auth.isAuthenticated) return <Navigate to="/login" />;

  return (
    <div>
      <Header
        username={auth.username}
        onLogout={() => dispatch(logout())}
        isBuildium={auth.isBuildium}
      />
      {children}
    </div>
  );
};

export default AppContainer;
