// @ts-nocheck
import AppContainer from '../../Components/AppContainer';
import useIndicesData from '../../hooks/indices';
import Filters from './Filters';
import BarChart from './BarChart';
import Table from './Tables';
import './indices.scss';
import moment from 'moment';

const Indices = () => {
  const { state, dispatch } = useIndicesData();

  return (
    <div className="app">
      <AppContainer>
        <h3>Beekin Recent Movers Indices</h3>
        <h4>
          Explore Beekin's rental indices by State or MSA, for single-family or
          multifamily.
        </h4>
        <div className="gridContainer">
          <div className="card">
            <Filters data={state?.filters} dispatch={dispatch} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '20px 0',
              }}>
              <button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 10,
                  backgroundColor: '#3b71c9',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'background-color 0.3s ease',
                }}
                onClick={() => {
                  const headers = [
                    'Location',
                    ...state.rawData[state.filters.selected.geography][
                      state.filters.selected.propertyType
                    ].dates.map((date) => moment(date).format('MMM-YY')),
                  ];

                  const chartData =
                    state.rawData[state.filters.selected.geography][
                      state.filters.selected.propertyType
                    ].barChartData;

                  const dataValues = Object.keys(chartData).map((location) => {
                    return [
                      location.replace(',', ' -'),
                      ...chartData[location].data.map((data) => data.value),
                    ];
                  });

                  const csvData = [headers, ...dataValues]
                    .map((row) => row.join(','))
                    .join('\n');

                  const blob = new Blob([csvData], {
                    type: 'text/csv;charset=utf-8;',
                  });

                  const link = document.createElement('a');

                  const url = URL.createObjectURL(blob);
                  link.href = url;

                  link.setAttribute(
                    'download',
                    `${state.filters.selected.geography}-${state.filters.selected.propertyType}.csv`
                  );

                  document.body.appendChild(link);

                  link.click();

                  document.body.removeChild(link);
                  URL.revokeObjectURL(url);
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = '#265299';
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = '#3b71c9';
                }}>
                Download table as CSV
              </button>
            </div>
          </div>
          <div className="card graph">
            <h5>{state?.filters.selected.location}</h5>
            <BarChart
              data={state?.data && state.data}
              geography={state?.filters.selected.geography}
              location={state?.filters.selected.location!}
              geojson={state?.geojson}
              measure={state?.filters.selected.measure!}
              selectedDate={state?.filters.selected.date!}
            />
          </div>
        </div>

        <div
          className="card indices-table"
          style={{
            overflow: 'scroll',
            margin: 20,
            maxHeight: 700,
          }}>
          {state?.data && (
            <Table
              data={
                state?.rawData &&
                state.rawData[state.filters.selected.geography][
                  state.filters.selected.propertyType
                ]
              }
              geography={state.filters.selected.geography}
              location={state.filters.selected.location}
              propertyType={state.filters.selected.propertyType}
              msaState={state.filters.selected.msaState}
              locations={state?.data && state.data.options}
              dates={state?.data && state.data.dates}
            />
          )}
        </div>
      </AppContainer>
    </div>
  );
};

export default Indices;
