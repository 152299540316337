import React from 'react';
import { getCognitoUrl } from '../../hooks/auth';
import '../../styles/index.scss';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

const Login = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  if (isAuthenticated) return <Navigate to={'/'} />;
  return (
    <div className="full place-center">
      <div className="login-container place-center">
        <div className="logo"></div>

        <a href={getCognitoUrl('login')} className="button-container ">
          <button className="frame login-btn">SIGN IN</button>
        </a>
      </div>
    </div>
  );
};

export default Login;
