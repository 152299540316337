import { Navigate, useLocation } from 'react-router-dom';

import Dashboard from '../Dashboard';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../redux/authSlice';
import { RootState } from '../../store';
import { useEffect } from 'react';


const Home = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  console.log(location.hash);
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get('action')?.includes('login')) {
      const tokens = new URLSearchParams(location.hash.slice(1));

      dispatch(
        login({
          idToken: tokens.get('id_token'),
          accessToken: tokens.get('access_token'),
        })
      );
    }
  }, [location, dispatch]);

  if (!isAuthenticated) return <Navigate to={'/login'} />;

  if (isAuthenticated) return <Dashboard></Dashboard>;
  return null;
};

export default Home;
