import React from 'react';

import './dialogWindow.scss';


function DialogWindow(props: any) {

  if (props.question) {
    return (
      <div className="modal-container">
        <div className="modal">
          <div className="question">{props.question}</div>
          <div className="answers">
            <div
              className="answer-yes"
              onClick={() => props.callBack(true)}
            >
              Yes
            </div>
            <div
              className="answer-no"
              onClick={() => props.callBack(false)}
            >
              No{' '}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

//Input.defaultProps = defaultProp;
export default DialogWindow;
