import { useEffect } from 'react';

import Router from './Router';
import { init } from './redux/authSlice';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(init());
  }, [dispatch]);
  return (
    <>
      <Router />;
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
      />
    </>
  );
};
export default App;
