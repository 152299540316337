import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';
import { RootState } from '../store';
import { toast } from 'react-toastify';

export const updateSettings = createAsyncThunk<
  any,
  any,
  {
    state: RootState;
  }
>('updateSetting', async (settings, thunkAPI) => {
  const state: RootState = thunkAPI.getState();

  return await api.updateSetting(settings, state.auth.userId!);
});

export const getSettings = createAsyncThunk<
  any,
  null,
  {
    state: RootState;
  }
>('getSettings', async (_, thunkAPI) => {
  const state = thunkAPI.getState();

  return await api.getSettings(state.auth.userId!);
});

type SettingsState = {
  buildium: {
    enableEbby: boolean;
    selectedDay: number;
    selectedTime: string;
    isFirst: boolean;
  };
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error?: string;
};
const initialState: SettingsState = {
  buildium: {
    enableEbby: false,
    selectedDay: 1,
    selectedTime: '08:00',
    isFirst: true,
  },
  loading: 'idle',
};

const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.buildium.enableEbby = action.payload?.data.settings.enableEbby;
        state.buildium.selectedDay = action.payload?.data.settings.selectedDay;
        state.buildium.selectedTime =
          action.payload?.data.settings.selectedTime;
        state.loading = 'succeeded';
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.loading = 'failed';
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.buildium.enableEbby = action.payload?.data.enableEbby;
        state.buildium.selectedDay = action.payload?.data.selectedDay;
        state.buildium.selectedTime = action.payload?.data.selectedTime;
        state.loading = 'succeeded';
        toast.info('Settings updated successfully', {
          position: 'top-right',
          hideProgressBar: true,
        });
      })
      .addCase(updateSettings.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateSettings.rejected, (state, action) => {
        state.loading = 'failed';
        toast.error('Something went wrong', {
          position: 'top-right',
          hideProgressBar: true,
        });
      });
  },
});

// Action creators are generated for each case reducer function
// export const {} = settingSlice.actions;

export default settingSlice.reducer;
