// @ts-nocheck
import { SyntheticEvent } from 'react';
import Select from '../../Components/Select';
import { USStates } from '../../constants';

type RadioProps = {
  options: Array<{ label: string; value: string }>;
  label: string;
  onChange?: any;
  value: string;
};
const Radio = (props: RadioProps) => {
  return (
    <div>
      <div className="label">{props.label}</div>
      {props.options.map((option) => {
        return (
          <span key={option.label} style={{ paddingRight: 30 }}>
            <label>
              <input
                type="radio"
                name={option.label}
                value={option.value}
                onChange={props.onChange}
                checked={props.value === option.value}
              />
              {option.label}
            </label>
          </span>
        );
      })}
    </div>
  );
};

const measureOptions = {
  indices: 'Index value',
  'yoy-perc': 'Year-over-year % change',
};

type FilterProps = {
  data: any;
  dispatch: any;
};
const Filters = (props: FilterProps) => {
  if (!props.data.options) return null;
  return (
    <div>
      <form
        style={{
          display: 'grid',
          gap: 20,
          padding: 20,
        }}>
        <Radio
          label="Select MF/SF"
          options={[
            {
              label: 'MF',
              value: 'MF',
            },
            {
              label: 'SF',
              value: 'SF',
            },
          ]}
          value={props.data.selected.propertyType}
          onChange={(val: SyntheticEvent) =>
            props.dispatch({
              type: 'filterUpdate',
              payload: val.target.value,
              property: 'propertyType',
            })
          }
        />

        <Radio
          label="Select geographical level"
          options={[
            {
              label: 'State',
              value: 'state',
            },
            {
              label: 'MSA',
              value: 'msa',
            },
          ]}
          value={props.data.selected.geography}
          onChange={(val: string) => {
            props.dispatch({
              type: 'filterUpdate',
              payload: val.target.value,
              property: 'geography',
            });
          }}
        />
        {props.data.selected.geography === 'state' && (
          <label>
            <Select
              name={'Location'}
              value={props.data.selected.location}
              options={props.data.options}
              callBack={(val) =>
                props.dispatch({
                  type: 'filterUpdate',
                  payload: val,
                  property: 'location',
                })
              }></Select>
          </label>
        )}

        {props.data.selected.geography === 'msa' && (
          <>
            <label>
              <Select
                mappedLabels={USStates}
                name={'Location'}
                value={props.data.selected.msaState}
                options={Object.keys(USStates)}
                callBack={(val) => {
                  props.dispatch({
                    type: 'filterUpdate',
                    payload: val,
                    property: 'msaState',
                  });
                }}></Select>
            </label>
            <label>
              <Select
                name={''}
                value={props.data.selected.location}
                options={props.data.msaOptions}
                callBack={(val) =>
                  props.dispatch({
                    type: 'filterUpdate',
                    payload: val,
                    property: 'location',
                  })
                }></Select>
            </label>
          </>
        )}
        <div>
          <Select
            mappedLabels={measureOptions}
            name="Select Measure"
            value={props.data.selected.measure}
            options={Object.keys(measureOptions)}
            callBack={(val) => {
              props.dispatch({
                type: 'filterUpdate',
                payload: val,
                property: 'measure',
              });
            }}></Select>
        </div>
      </form>
    </div>
  );
};

export default Filters;
