// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import jwtDecode from 'jwt-decode';
import AWS from 'aws-sdk';
import get from 'lodash.get';
import { toast } from 'react-toastify';
import { validateToken } from '../api';

export const awsRegion = get(window, 'config.region');
export const cognitoRegion = get(window, 'config.region');
export const cognitoUserPoolId = get(window, 'config.userPoolId');
export const cognitoIdentityPoolId = get(window, 'config.identityPoolId');
export const cognitoClientId = get(window, 'config.userPoolClientId');
export const cognitoDomain = get(window, 'config.userPoolDomain');

const rentalAVMUsageId = 'd8aazf';

export type AuthState = {
  isAuthenticated: boolean;
  idToken?: string;
  accessToken?: string;
  username?: string;
  userId?: string;
  logoutTimer: NodeJS.Timeout | undefined;
  signup: {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  };
  isBuildium: boolean;
  apiKey?: string;
  userType?: string;
  apiKeyLoading: boolean;
};

export const authState: AuthState = {
  apiKeyLoading: false,
  isAuthenticated: false,
  idToken: undefined,
  accessToken: undefined,
  username: undefined,
  userId: undefined,
  logoutTimer: undefined,
  signup: {
    loading: 'idle',
  },
  isBuildium: false,
};

const getLoginRedirectUrl = () =>
  `${window.location.protocol}//${window.location.host}/index.html?action=login`;
const getLogoutRedirectUrl = () =>
  `${window.location.protocol}//${window.location.host}/index.html?action=logout`;

export function getCognitoUrl(type: string) {
  const redirectUri = getLoginRedirectUrl();
  return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`;
}

function getRemainingSessionTime(idToken: string) {
  return jwtDecode<any>(idToken).exp * 1000 - Date.now();
}

AWS.config.region = cognitoRegion;

let cachedClient;
let cachedClientWithCredentials;

function initApiGatewayClient({
  accessKeyId,
  secretAccessKey,
  sessionToken,
} = {}) {
  cachedClient = window.apigClientFactory.newClient({
    accessKey: accessKeyId,
    secretKey: secretAccessKey,
    sessionToken: sessionToken,
    region: awsRegion,
  });

  if (accessKeyId && secretAccessKey && sessionToken) {
    cachedClientWithCredentials = cachedClient;
  }

  window.apigw = cachedClient;
}

export function apiGatewayClient() {
  if (cachedClient) return Promise.resolve(cachedClient);
  return new Promise((resolve) => {
    const poller = setInterval(() => {
      if (cachedClient) {
        clearInterval(poller);
        resolve(cachedClient);
      }
    }, 100);
  });
}

export function apiGatewayClientWithCredentials() {
  if (cachedClientWithCredentials) {
    return Promise.resolve(cachedClientWithCredentials);
  }
  return new Promise((resolve) => {
    const poller = setInterval(() => {
      if (cachedClientWithCredentials) {
        clearInterval(poller);
        resolve(cachedClientWithCredentials);
      }
    }, 100);
  });
}

async function checkAndUpdateSubscription(usagePlanId) {
  const apiGatewayClient = await apiGatewayClientWithCredentials();
  function updateSubscriptions() {
    // eslint-disable-next-line no-return-assign

    return apiGatewayClient
      .get('/subscriptions', {}, {}, {})
      .then(({ data }) => {
        const isSubscribed = data && data.find((sub) => sub.id === usagePlanId);
        if (!isSubscribed) return subscribe(usagePlanId);
      });
  }

  function subscribe(usagePlanId) {
    return apiGatewayClient.put('/subscriptions/' + usagePlanId, {}, {});
  }

  return updateSubscriptions();
}

function setCredentials(idToken?: string) {
  const preferredRole = jwtDecode<any>(idToken)['cognito:preferred_role'];
  const params: any = {
    IdentityPoolId: cognitoIdentityPoolId,
    Logins: {
      [`cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`]:
        idToken,
    },
  };

  if (preferredRole) params.RoleArn = preferredRole;

  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);

  return new Promise((resolve, reject) => {
    AWS?.config?.credentials?.refresh((error: any) => {
      if (error) {
        console.error(error);
        return reject(error);
      }

      initApiGatewayClient(AWS.config.credentials);

      resolve();
      return apiGatewayClient().then((apiGatewayClient) =>
        apiGatewayClient.post('/signin', {}, {}, {})
      );
    });
  });
}

function loginHelper(state, idToken: string) {
  let diff = 0;
  if (idToken) diff = getRemainingSessionTime(idToken);
  if (diff > 0) {
    state.logoutTimer = setTimeout(logout, diff);
    let user = jwtDecode<any>(idToken!);
    console.log(user);
    state.username = user.email ? user.email : user;
    state.isAuthenticated = true;
    state.idToken = idToken!;
    state.userId = user.sub;
    state.isBuildium = user['custom:referer'] === 'buildium';

    setCredentials(idToken);
    localStorage.setItem(cognitoUserPoolId, idToken);
  } else {
    initApiGatewayClient(); // init a blank client (will get overwritten if we have cred)
  }
}

function logoutHelper(state) {
  window.localStorage.clear();
  clearTimeout(state.logoutTimer);
  if (cognitoDomain) {
    // redirect to cognito to log out there, too
    const redirectUrl = getLogoutRedirectUrl();
    window.location = `${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${redirectUrl}`;
  }
  state = authState;
}

export const validateBuildiumToken = createAsyncThunk(
  'signup/validateBuildiumToken',
  async (token: string, thunkAPI) => {
    const response = await validateToken(token);
    return response.data;
  }
);

export const updateApiKey = createAsyncThunk(
  'updateApiKey',
  async (thunkAPI) => {
    const MAX_RETRIES = 5;
    let remaining = MAX_RETRIES;

    const timeouts = [250, 500, 1000, 2000];
    function loop() {
      remaining--;
      const promise = apiGatewayClientWithCredentials()
        .then((apiGatewayClient) => apiGatewayClient.get('/apikey', {}, {}, {}))
        .then(({ data }) => {
          return data.value;
        });

      return remaining
        ? promise.catch((err) => {
            new Promise((resolve) =>
              setTimeout(resolve, timeouts[remaining])
            ).then(loop);
          })
        : promise;
    }
    return checkAndUpdateSubscription(rentalAVMUsageId)
      .then(() => loop())

      .catch((err) => {
        console.log(`${err.message}`);
        thunkAPI.rejectWithValue(err);
      });
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    login: (state, action) => {
      const { idToken, accessToken } = action.payload;
      try {
        if (idToken) {
          // we get both, we set both, but we only really care about the idToken
          loginHelper(state, idToken);
          state.accessToken = accessToken;
        }
      } catch (error) {
        reject(error);
      }
    },
    logout: (state) => {
      logoutHelper(state);
    },
    init: (state) => {
      let idToken;

      try {
        idToken = window.localStorage.getItem(cognitoUserPoolId);
        loginHelper(state, idToken);
      } catch (error) {
        console.error(error);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateBuildiumToken.pending, (state) => {
        state.signup.loading = 'pending';
      })
      .addCase(validateBuildiumToken.fulfilled, (state, action) => {
        state.signup.loading = 'succeeded';
        const { token, apiKey, userType } = action.payload;
        if (token) {
          loginHelper(state, token.IdToken);
          state.accessToken = token.AccessToken;
        }
        state.apiKey = apiKey;
        state.userType = userType;
      })
      .addCase(validateBuildiumToken.rejected, (state) => {
        state.signup.loading = 'failed';
      })
      .addCase(updateApiKey.pending, (state) => {
        state.apiKeyLoading = true;
      })
      .addCase(updateApiKey.fulfilled, (state, action) => {
        state.apiKeyLoading = false;
        state.apiKey = action.payload;
      })
      .addCase(updateApiKey.rejected, (state) => {
        state.apiKeyLoading = false;
        toast.error('Something went wrong!, Please contact support team', {
          autoClose: false,
          position: 'top-right',
          hideProgressBar: true,
        });
      });
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, init } = authSlice.actions;

export default authSlice.reducer;
