export const propertyTypeMapping: { [index: string]: any } = {
  '0': 'Townhouse',
  '1': 'Single Family',
  '2': 'Multifamily/Apartment',
  '3': 'Condo',
};

export const LocationType = {
  ADDRESS: 'address',
  COORDINATES: 'co-ordinates',
};

export const TableColumns = {
  [LocationType.ADDRESS]: [
    'address',
    'sqft',
    'beds',
    'baths',
    'property_type',
    'year_built',
    'estimated_rent',
    'lower',
    'upper',
    'forecast_standard_deviation',
    'confidence',
    'error_reason',
  ],
  [LocationType.COORDINATES]: [
    'lat_long',
    'sqft',
    'beds',
    'baths',
    'property_type',
    'year_built',
    'estimated_rent',
    'lower',
    'upper',
    'forecast_standard_deviation',
    'confidence',
    'error_reason',
  ],
};

export const ValidPropertyTypeValues = [
  0,
  1,
  2,
  3,
  '0',
  '1',
  '2',
  '3',
  'Townhouse',
  'Single Family',
  'Multifamily/Apartment',
  'Condo',
];

export const USStates = {
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
};
