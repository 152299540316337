// @ts-nocheck
import jwtDecode from 'jwt-decode';
import AWS from 'aws-sdk';
import get from 'lodash.get';
import { useReducer } from 'react';
import queryString from 'query-string';
import { toast } from 'react-toastify';

export const awsRegion = get(window, 'config.region');
export const cognitoRegion = get(window, 'config.region');
export const cognitoUserPoolId = get(window, 'config.userPoolId');
export const cognitoIdentityPoolId = get(window, 'config.identityPoolId');
export const cognitoClientId = get(window, 'config.userPoolClientId');
export const cognitoDomain = get(window, 'config.userPoolDomain');

const rentalAVMUsageId = 'd8aazf';

const authState = {
  isAuthenticated: false,
  idToken: undefined,
  username: undefined,
  userId: undefined,
};

const getLoginRedirectUrl = () =>
  `${window.location.protocol}//${window.location.host}/index.html?action=login`;
const getLogoutRedirectUrl = () =>
  `${window.location.protocol}//${window.location.host}/index.html?action=logout`;

export function getCognitoUrl(type: string) {
  const redirectUri = getLoginRedirectUrl();
  return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`;
}

function getRemainingSessionTime(idToken: string) {
  return jwtDecode<any>(idToken).exp * 1000 - Date.now();
}

// export const AuthContext = createContext();

const authReducer = (state: typeof authState, action: any) => {
  switch (action.type) {
    case 'login/success':
      const { username } = action.payload;
      return Object.assign({}, state, action.payload, {
        isAuthenticated: true,
        username: username.email ? username.email : username,
      });
    case 'login/failed':
    case 'logout':
      return authState;
    default:
      break;
  }
};

export const useAuth = () => {
  const [state, dispatch] = useReducer(authReducer, authState);

  let logoutTimer: NodeJS.Timeout | undefined;

  function init() {
    let idToken;
    let diff = 0;

    try {
      const { action } = queryString.parse(window.location.search);
      if (action === 'login') {
        login();
      } else if (action === 'logout') {
        window.location.replace('/');
      }
    } finally {
      console.log(``);
    }
    if (window.location.pathname === '/') {
      try {
        idToken = window.localStorage.getItem(cognitoUserPoolId);
        if (idToken) diff = getRemainingSessionTime(idToken);
      } catch (error) {
        console.error(error);
      }

      if (diff > 0) {
        logoutTimer = setTimeout(logout, diff);
        let user = jwtDecode<any>(idToken);
        console.log(user);

        dispatch({
          type: 'login/success',
          payload: { idToken, username: user.email, userId: user.sub },
        });
        setCredentials(idToken);
      } else {
        initApiGatewayClient(); // init a blank client (will get overwritten if we have cred)
        logout();
      }
    }
  }

  /**
   * Gets triggered by the callback from the cognito user pool. Pretty much all it does is grab and state the idToken.
   */
  function login() {
    return new Promise((resolve, reject) => {
      let idToken;
      // let accessToken,

      try {
        window.location.hash
          .replace(/^#/, '')
          .split('&')
          .map((param) => param.split('='))
          .forEach((param) => {
            // record the id_token and access_token
            if (param[0] === 'id_token') idToken = param[1];
            // if (param[0] === 'access_token') accessToken = param[1]
          });

        if (idToken) {
          // we get both, we set both, but we only really care about the idToken
          const user = jwtDecode<any>(idToken);

          window.localStorage.setItem(cognitoUserPoolId, idToken);

          dispatch({
            type: 'login/success',
            payload: { idToken, username: user.email, userId: user.sub },
          });

          logoutTimer = setTimeout(logout, getRemainingSessionTime(idToken));

          window.location.replace('/');
          resolve(idToken);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  function setCredentials(idToken) {
    const preferredRole = jwtDecode<any>(idToken)['cognito:preferred_role'];
    const params: any = {
      IdentityPoolId: cognitoIdentityPoolId,
      Logins: {
        [`cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`]:
          idToken,
      },
    };

    if (preferredRole) params.RoleArn = preferredRole;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);

    return new Promise((resolve, reject) => {
      AWS?.config?.credentials?.refresh((error: any) => {
        if (error) {
          console.error(error);
          return reject(error);
        }

        initApiGatewayClient(AWS.config.credentials);

        resolve();
        return apiGatewayClient().then((apiGatewayClient) =>
          apiGatewayClient.post('/signin', {}, {}, {})
        );
      });
    });
  }

  /**
   * Callback for the Cognito User Pool's logout just to make sure we clean up everything.
   */
  function logout() {
    clearTimeout(logoutTimer);
    logoutTimer = undefined;
    if (state.idToken) {
      dispatch({
        type: 'auth/logout',
      });
      window.localStorage.clear();

      if (cognitoDomain) {
        // redirect to cognito to log out there, too
        const redirectUrl = getLogoutRedirectUrl();
        window.location = `${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${redirectUrl}`;
      }
    }
  }
  return { state, init, login, logout };
};

AWS.config.region = cognitoRegion;

let cachedClient;
let cachedClientWithCredentials;

function initApiGatewayClient({
  accessKeyId,
  secretAccessKey,
  sessionToken,
} = {}) {
  cachedClient = window.apigClientFactory.newClient({
    accessKey: accessKeyId,
    secretKey: secretAccessKey,
    sessionToken: sessionToken,
    region: awsRegion,
  });

  if (accessKeyId && secretAccessKey && sessionToken) {
    cachedClientWithCredentials = cachedClient;
  }

  window.apigw = cachedClient;
}

export function apiGatewayClient() {
  if (cachedClient) return Promise.resolve(cachedClient);
  return new Promise((resolve) => {
    const poller = setInterval(() => {
      if (cachedClient) {
        clearInterval(poller);
        resolve(cachedClient);
      }
    }, 100);
  });
}

export function apiGatewayClientWithCredentials() {
  if (cachedClientWithCredentials) {
    return Promise.resolve(cachedClientWithCredentials);
  }
  return new Promise((resolve) => {
    const poller = setInterval(() => {
      if (cachedClientWithCredentials) {
        clearInterval(poller);
        resolve(cachedClientWithCredentials);
      }
    }, 100);
  });
}

async function checkAndUpdateSubscription(usagePlanId) {
  const apiGatewayClient = await apiGatewayClientWithCredentials();
  function updateSubscriptions() {
    // eslint-disable-next-line no-return-assign

    return apiGatewayClient
      .get('/subscriptions', {}, {}, {})
      .then(({ data }) => {
        const isSubscribed = data && data.find((sub) => sub.id === usagePlanId);
        if (!isSubscribed) return subscribe(usagePlanId);
      });
  }

  function subscribe(usagePlanId) {
    return apiGatewayClient.put('/subscriptions/' + usagePlanId, {}, {});
  }

  return updateSubscriptions();
}
export function updateApiKey(dispatch) {
  const MAX_RETRIES = 5;
  let remaining = MAX_RETRIES;

  const timeouts = [250, 500, 1000, 2000];

  function loop() {
    remaining--;
    const promise = apiGatewayClientWithCredentials()
      .then((apiGatewayClient) => apiGatewayClient.get('/apikey', {}, {}, {}))
      .then(({ data }) =>
        dispatch({
          type: 'updateConfig',
          payload: { name: 'apiKey', value: data.value },
        })
      );

    return remaining
      ? promise.catch((err) => {
          new Promise((resolve) =>
            setTimeout(resolve, timeouts[remaining])
          ).then(loop);
        })
      : promise;
  }
  checkAndUpdateSubscription(rentalAVMUsageId)
    .then(() => loop())

    .catch((err) => {
      console.log(`${err.message}`);
      toast.error('Something went wrong!, Please contact support team', {
        autoClose: false,
        position: 'top-right',
        hideProgressBar: true,
      });
    });
}
