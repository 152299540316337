import saveAs from 'file-saver';
import React from 'react';
import { toast } from 'react-toastify';
import { LocationType } from '../constants';
import SelectFile from './SelectFile';
import { updateConfig } from '../redux/ebbySlice';

type UploadExcelPopupProps = {
  closeModal: (toggle: boolean) => void;
  apiKey?: string;
  dispatch: React.Dispatch<any>;
  locationType: string;
};

function UploadExcelPopup(props: UploadExcelPopupProps): JSX.Element {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>Upload Excel</h2>
        </div>
        <div className="modal-body">
          <p>Note:</p>
          <ol>
            <li>
              Expected columns in excel:{' '}
              {props.locationType === LocationType.ADDRESS ? (
                <>
                  <strong>address</strong>,<strong>sqft</strong>,
                  <strong>beds</strong>,<strong>baths</strong>,
                  <strong>property_type</strong>,<strong>year_built</strong>
                </>
              ) : (
                <>
                  <strong>lat_long</strong>,<strong>sqft</strong>,
                  <strong>beds</strong>,<strong>baths</strong>,
                  <strong>property_type</strong>,<strong>year_built</strong>
                </>
              )}
              .
              <br />
              <span
                onClick={() => {
                  saveAs(
                    process.env.PUBLIC_URL +
                      `/template_${props.locationType}.xlsx`,
                    `template_${props.locationType}.xlsx`
                  );
                }}>
                click here for template excel
              </span>
            </li>
            <li>
              Required columns in excel:{' '}
              {props.locationType === LocationType.ADDRESS ? (
                <>
                  <strong>address</strong>,<strong>sqft</strong>,
                  <strong>beds</strong>,<strong>baths</strong>
                </>
              ) : (
                <>
                  <strong>lat_long</strong>,<strong>sqft</strong>,
                  <strong>beds</strong>,<strong>baths</strong>
                </>
              )}
              .
            </li>
            <li>
              <p>
                Below table contains the supported property types and their
                valid values. If anything other than valid values is specified
                in the property_type column then that row will be ignored.
              </p>
              <table>
                <thead>
                  <tr>
                    <th>Property Type</th>
                    <th> Valid Values</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Townhouse</td>
                    <td>
                      {' '}
                      0 or <strong>Townhouse</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Single Family</td>
                    <td>
                      {' '}
                      1 or <strong>Single Family</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Multi Family/Apartments</td>
                    <td>
                      {' '}
                      2 or <strong>Multifamily/Apartment</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Condo</td>
                    <td>
                      {' '}
                      3 or <strong>Condo</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              Columns other than expected will be removed during processing.
            </li>
          </ol>
        </div>
        <div className="modal-footer">
          <SelectFile
            onClick={(e) => {
              if (!props.apiKey) {
                e.preventDefault();
                toast('Fetching API Key. Please try again in few seconds', {
                  type: 'info',
                });
                return;
              }
            }}
            onChange={(value) => {
              props.dispatch(
                updateConfig({ name: 'selectFile', value: value })
              );
              props.closeModal(false);
            }}
            icon="upload-icon"
          />
          <button
            type="button"
            title="modal_close"
            className="btn"
            onClick={() => props.closeModal(false)}>
            {' '}
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadExcelPopup;
