// @ts-nocheck
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

type TableProps = {
  data: any;
  dates: any;
  locations: any;
  geography: any;
  propertyType: any;
  stateName: any;
  msaState: any;
  location: any;
};

const Table = (props: TableProps) => {
  const [data, setData] = useState({});
  const [columns, setColumns] = useState([]);

  const tableData = useMemo(() => {
    let tableData =
      Object.keys(data).length > 0 && props.propertyType && props.geography
        ? data[props.propertyType][props.geography].data
        : [];
    if (props.geography === 'msa') {
      tableData = tableData.filter((row) =>
        row.Location.includes(`(${props.msaState.toLocaleUpperCase()})`)
      );

      const index = tableData.findIndex(
        (row) => row.Location === props.location
      );

      const [element] = tableData.splice(index, 1);

      tableData.unshift(element);
    }

    return tableData;
  }, [
    data,
    props.geography,
    props.msaState,
    props.location,
    props.propertyType,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/data/table-data.json');
        const data = response.data;
        setData(data);
        setColumns(data.columns);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const options = {
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enablePinning: true,
  };
  const table = useReactTable(options);

  return (
    <table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className="titles" key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                <div className="value">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
