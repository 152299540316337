import axios from 'axios';
import { LocationType } from './constants';
import get from 'lodash.get';
export const cognitoUserPoolId = get(window, 'config.userPoolId');

const V2_API_URL = 'https://api.beekin.co/rental-avm/v2/rent-estimate';

const ebbyBackend = 'https://mus1dbmqeg.execute-api.us-east-1.amazonaws.com';
// const ebbyBackend = 'http://localhost:4000';

const GET_USER = ebbyBackend + '/user';
const ACCEPT_TERMS = ebbyBackend + '/accept-terms';
const VALIDATE_BUILDIUM_TOKEN = ebbyBackend + '/buildium/validate-token';
const UPDATE_SETTING = ebbyBackend + '/settings';
const getHeaders = (apiKey: string) => {
  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Api-Key': apiKey,
  };
};

type API_Request = {
  address?: string;
  lat_long?: string;
  baths: number;
  beds: number;
  property_type: number;
  sqft: number;
  year_built?: number;
  locationType?: string;
};

export const rentEstimateV2 = async (data: API_Request, apiKey: string) => {
  let params = new URLSearchParams();
  if (data.locationType === LocationType.ADDRESS && data.address) {
    params.append('address', data.address);
  } else if (data.locationType === LocationType.COORDINATES && data.lat_long) {
    params.append('latlng', data.lat_long);
  }
  params.append('baths', data.baths.toString());
  params.append('beds', data.beds.toString());
  params.append('property_type', data.property_type.toString());
  params.append('sqft', data.sqft.toString());
  data.year_built && params.append('year_built', data.year_built.toString());

  return axios.get(V2_API_URL, {
    params,
    headers: getHeaders(apiKey),
  });
};

export const getUser = async (userId: string) => {
  return axios.get(`${GET_USER}/${userId}`, {
    headers: {
      token: localStorage.getItem(cognitoUserPoolId),
    },
  });
};
export const acceptTerms = async (userId: string) => {
  return axios.put(ACCEPT_TERMS, { userId });
};

export const getIndicesData = async (
  locationType: string,
  geography: string
) => {
  return axios.get(`/data/${geography}-${locationType.toLowerCase()}.json`);
};

export const validateToken = async (token: string) => {
  return axios.post(`${VALIDATE_BUILDIUM_TOKEN}`, {
    token,
  });
};

const api = {
  updateSetting: async (
    setting: {
      enableEbby: boolean;
      selectedDay: string;
      selectedTime: string;
      isFirst: boolean;
    },
    userId: string
  ) => {
    return await axios.put(`${UPDATE_SETTING}/${userId}`, setting);
  },
  getSettings: async (userId: string) => {
    return axios.get(`${UPDATE_SETTING}/${userId}`);
  },
};
export default api;
