// @ts-nocheck
import { useReducer, useEffect } from 'react';
import { getIndicesData } from '../api';
import { produce } from 'immer';
// import moment from 'moment';

// function filterData(obj) {
//   const threshold = 0.5; // threshold to decide "most months"
//   const filtered = {};
//   const removedLocations = [];

//   Object.entries(obj).forEach(([key, value]) => {
//     const totalMonths = value.data.length;
//     const monthsWithHundred = value.data.filter(
//       (item) => item.value === 100
//     ).length;

//     if (monthsWithHundred / totalMonths <= threshold) {
//       filtered[key] = value;
//     } else {
//       removedLocations.push(key);
//     }
//   });

//   return { filteredData: filtered, removedLocations };
// }

// const parseCsvState = (csvString: string) => {
//   const rows = csvString.split('\n');
//   let location = [],
//     dates = [];
//   const barChartData = {};

//   if (rows.length > 0) {
//     const headers = rows[0].split(',');
//     location = headers.slice(1);

//     location.forEach((state) => {
//       barChartData[state] = {
//         minValue: Infinity,
//         data: [],
//       };
//     });

//     for (let i = 1; i < rows.length; i++) {
//       const dataRow = rows[i].split(',');
//       if (dataRow.length > 0 && dataRow[0] !== '') {
//         dates.push(dataRow[0]);
//       }
//     }

//     rows.slice(1).forEach((row) => {
//       const [date, ...values] = row.split(',');
//       values.forEach((value, index) => {
//         const formattedValue = parseFloat((parseFloat(value) * 100).toFixed(2));

//         barChartData[location[index]].data.push({
//           date: moment(date).format('MMM-YY'),
//           value: formattedValue,
//         });

//         barChartData[location[index]].minValue =
//           formattedValue < barChartData[location[index]].minValue
//             ? formattedValue
//             : barChartData[location[index]].minValue;
//       });
//     });
//   }

//   const { removedLocations } = filterData(barChartData);

//   location = location.filter((loc) => !removedLocations.includes(loc));

//   return {
//     location,
//     dates,
//     barChartData,
//   };
// };

// const parseCsvMsa = (csvString: string) => {
//   const rows = csvString.split('\n');
//   let location = [],
//     dates = [];
//   const barChartData = {};

//   if (rows.length > 0) {
//     const states = rows[0].split(',');
//     location = states.slice(1);

//     const msa = rows[1]
//       .split('"')
//       .filter((elem) => elem !== '' && elem !== ',');
//     location = location.map((state, index) => {
//       return `${msa[index]} (${state || 'Combined'})`;
//     });

//     location.forEach((msa) => {
//       barChartData[msa] = {
//         minValue: Infinity,
//         data: [],
//       };
//     });

//     for (let i = 2; i < rows.length; i++) {
//       const dataRow = rows[i].split(',');
//       if (dataRow.length > 0 && dataRow[0] !== '') {
//         dates.push(dataRow[0]);
//       }
//     }

//     rows.slice(2).forEach((row) => {
//       const [date, ...values] = row.split(',');
//       values.forEach((value, index) => {
//         const formattedValue = parseFloat((parseFloat(value) * 100).toFixed(2));

//         barChartData[location[index]].data.push({
//           date: moment(date).format('MMM-YY'),
//           value: formattedValue,
//         });

//         barChartData[location[index]].minValue =
//           formattedValue < barChartData[location[index]].minValue
//             ? formattedValue
//             : barChartData[location[index]].minValue;
//       });
//     });
//   }

//   const { filteredData: filteredBarChartData, removedLocations } =
//     filterData(barChartData);

//   location = location.filter((loc) => !removedLocations.includes(loc));

//   return {
//     location,
//     dates,
//     barChartData: filteredBarChartData,
//   };
// };

const initialIndicesState = {
  filters: {
    selected: {
      propertyType: 'MF',
      geography: 'state',
      measure: 'yoy-perc',
      location: 'All_USA',
      date: undefined,
      msaState: 'AL',
    },
    msaOptions: [],
  },
  data: undefined,
  rawData: {},
  geojson: undefined,
};

function calculateYoYChange(data) {
  const result = {};

  for (const key in data) {
    const stateData = data[key].data;
    const newStateData = [];

    for (let i = 12; i < stateData.length; i++) {
      const currentYearValue = stateData[i].value;
      const previousYearValue = stateData[i - 12].value;
      const yoYChange = parseFloat(
        ((currentYearValue - previousYearValue) / previousYearValue) * 100
      );

      // Store the YoY change in the new data structure
      newStateData.push({
        date: stateData[i].date,
        value: yoYChange.toFixed(2), // Formatting to two decimal places
      });
    }

    result[key] = {
      data: newStateData,
    };
  }

  return result;
}

const updateGraphData = (rawData, filters) => {
  let data = null;
  if (
    rawData &&
    rawData[filters.selected.geography] !== undefined &&
    rawData[filters.selected.geography][filters.selected.propertyType] !==
      undefined
  ) {
    const payload =
      rawData[filters.selected.geography][filters.selected.propertyType];

    return {
      options: payload.location,
      dates: payload.dates,
      barChartData:
        filters.selected.measure === 'indices'
          ? payload.barChartData
          : calculateYoYChange(payload.barChartData),
    };
  }
  return data;
};

const indicesReducer = (state = initialIndicesState, action: any) => {
  switch (action.type) {
    case 'filterUpdate':
      return produce(state, (draft) => {
        draft.filters.selected[action.property] = action.payload;
        if (action.property === 'geography') {
          if (action.payload === 'msa') {
            draft.filters.selected.location = 'Anniston-Oxford, AL (AL)';
          } else if (action.payload === 'state')
            draft.filters.selected.location = 'All_USA';
          draft.rawData[state.filters.selected.geography][
            state.filters.selected.propertyType
          ].location = [];
        }
        draft.data = updateGraphData(state.rawData, draft.filters);

        if (
          (action.property === 'msaState' &&
            state.filters.selected.msaState !== action.payload) ||
          (action.property === 'geography' &&
            action.payload === 'msa' &&
            draft.data &&
            draft.filters.msaOptions.length === 0)
        ) {
          draft.filters.msaOptions = draft.data?.options.filter((option) =>
            option.includes(`(${action.payload.toLocaleUpperCase()})`)
          );
          draft.filters.selected.location = draft.filters.msaOptions[0];
        } else if (
          state.filters.selected.geography === 'msa' &&
          draft.data &&
          action.property === 'propertyType' &&
          state.filters.selected.propertyType !== action.payload
        ) {
          draft.filters.msaOptions = draft.data?.options.filter((option) =>
            option.includes(
              `(${draft.filters.selected.msaState.toLocaleUpperCase()})`
            )
          );
          if (
            !draft.filters.msaOptions.includes(draft.filters.selected.location)
          )
            draft.filters.selected.location = draft.filters.msaOptions[0];
        }
        localStorage.setItem('filters', JSON.stringify(draft.filters));
      });

    case 'updateRawData': {
      return produce(state, (draft) => {
        if (!draft.rawData[state.filters.selected.geography]) {
          draft.rawData[state.filters.selected.geography] = {};
        }
        draft.rawData[state.filters.selected.geography][
          state.filters.selected.propertyType
        ] = action.payload;
        draft.filters.options = action.payload.location;
        draft.filters.selected.date =
          action.payload.dates[action.payload.dates.length - 1];
        draft.data = updateGraphData(draft.rawData, draft.filters);
        if (
          draft.filters.selected.geography === 'msa' &&
          draft.filters.msaOptions.length === 0
        ) {
          draft.filters.msaOptions = draft.data?.options.filter((option) =>
            option.includes(
              `(${draft.filters.selected.msaState.toLocaleUpperCase()})`
            )
          );
          draft.filters.selected.location = draft.filters.msaOptions[0];
        }
        localStorage.setItem('filters', JSON.stringify(draft.filters));
      });
    }
    case 'msaGeosjson': {
      state.geojson = action.payload;
      return state;
    }
    default:
      break;
  }
};

const useIndicesData = () => {
  const filters = localStorage.getItem('filters');
  const [state, dispatch] = useReducer(indicesReducer, {
    ...initialIndicesState,
    filters: filters ? JSON.parse(filters) : initialIndicesState.filters,
  });

  useEffect(() => {
    if (
      !state.rawData ||
      !state?.rawData[state.filters.selected.geography] ||
      !state?.rawData[state.filters.selected.geography][
        state.filters.selected.propertyType
      ] ||
      state?.rawData[state.filters.selected.geography][
        state.filters.selected.propertyType
      ].location.length === 0
    ) {
      getIndicesData(
        state?.filters.selected.propertyType,
        state?.filters.selected.geography
      ).then((res) => {
        let data = res.data;
        dispatch({ type: 'updateRawData', payload: data });
      });
    }
  }, [
    state.rawData,
    state.filters.selected.propertyType,
    state.filters.selected.geography,
    state.filters.selected.location,
  ]);

  // if (!state.geojson) {
  //   loadMsaGeojson().then((res) => {
  //     dispatch({
  //       type: 'msaGeosjson',
  //       payload: res.data,
  //     });
  //   });
  // }

  return {
    state,
    dispatch,
  };
};

export default useIndicesData;
