// @ts-nocheck
import { LocationType, propertyTypeMapping } from '../constants';
import { createSlice } from '@reduxjs/toolkit';

let appState = {
  openUploadDialog: false,
  selectedTab: 'ebby',
  queue: [],
  termsAccepted: true,
  estimatedData: {
    [LocationType.ADDRESS]: [],
    [LocationType.COORDINATES]: [],
  },
  includeErrorItems: true,
  config: {
    apiKey: {
      value: '',
      name: 'API Key',
    },

    selectFile: {
      name: 'Upload Excel',
      icon: 'upload-icon',
      value: null,
    },
    shouldIncludeInvalidRow: {
      name: 'shouldIncludeInvalidRow',
      value: true,
    },
  },
  queueNumber: 0,
  locationType: LocationType.ADDRESS,
  templates: {
    [LocationType.ADDRESS]: [
      {
        name: 'address',
        value: '',
        type: 'text',
        width: 'calc(100% - 720px)',
        placeholder: '123 Main Street, New York, NY 11354',
        locationType: LocationType.ADDRESS,
      },
      {
        name: 'sqft',
        value: '',
        type: 'text',
        width: '70px',
        placeholder: '1000',
      },
      {
        name: 'beds',
        value: '',
        type: 'text',
        width: '50px',
        placeholder: '2',
      },
      {
        name: 'baths',
        value: '',
        type: 'text',
        width: '50px',
        placeholder: '2',
      },
      {
        name: 'property_type',
        value: '0',
        type: 'select',
        options: ['0', '1', '2', '3'],
        width: '180px',
      },
      {
        name: 'year_built',
        value: '',
        type: 'text',
        width: '70px',
        placeholder: '2010',
      },
    ],
    [LocationType.COORDINATES]: [
      {
        name: 'lat_long',
        value: '',
        type: 'text',
        width: 'calc(100% - 750px',
        placeholder: '41.1127754,-81.5029119',
      },
      {
        name: 'sqft',
        value: '',
        type: 'text',
        width: '70px',
        placeholder: '1000',
      },
      {
        name: 'beds',
        value: '',
        type: 'text',
        width: '50px',
        placeholder: '2',
      },
      {
        name: 'baths',
        value: '',
        type: 'text',
        width: '50px',
        placeholder: '2',
      },
      {
        name: 'property_type',
        value: '0',
        type: 'select',
        options: ['0', '1', '2', '3'],
        width: '180px',
      },
      {
        name: 'year_built',
        value: '',
        type: 'text',
        width: '70px',
        placeholder: '2010',
      },
    ],
  },
};

export const initialState = appState;

const ebbySlice = createSlice({
  name: 'ebby',
  initialState,
  reducers: {
    toggleUploadDialog: (state, action) => {
      state.openUploadDialog = action.payload;
    },
    updateForm: (state, action) => {
      const { key, value, locationType } = action.payload;
      const idx = state.templates[locationType].findIndex(
        (el) => el.name === key
      );
      state.templates[locationType][idx].value = value;
    },
    resetForm: (state) => {
      state.templates = initialState.templates;
    },
    getEstimate: (state, action) => {
      state.queue.push(action.payload);
      state.templates[state.locationType].forEach((el: any) => {
        if (el.name === 'property_type') {
          el.value = '0';
        } else el.value = '';
      });
    },
    updateQueueByForm: (state, action) => {
      let query: any = {};
      state.template.forEach((item: any) => {
        query[item.name] = item.value;
      });

      state.queue.push(query);
      state.queueNumber = state.queueNumber + 1;
      return state;
    },
    updateQueue: (state, action) => {
      action.payload.validatedForm.forEach((item: any) => {
        state.queue.push(item);
      });
      state.config.selectFile.value = null;
      state.queueNumber =
        state.queueNumber + action.payload.validatedForm.length;
      return state;
    },
    queryRejected: (state, action) => {
      state.queue = [];
      state.queueNumber = 0;
      return state;
    },
    updateLocationType: (state, action) => {
      state.locationType = action.payload.newLocationType;
    },
    updateProcessed: (state, action) => {
      const { data, queue } = action.payload.response;
      const locationType = queue.locationType;
      if (data && data.geolocation) {
        const [lat, long] = Object.values(data.geolocation);
        const latLong = `Lat:${lat}, Lng:${long}`;
        data.geolocation = latLong;
      }

      const pType = propertyTypeMapping[queue.property_type];

      const processObj = {
        ...action.payload.response.queue,
        property_type: pType,
        year_built: queue['year_built'] ? queue['year_built'] : '-',
        geolocation: data?.geolocation ? data.geolocation : '-',
        estimated_rent: data?.estimated_rent ? data.estimated_rent : '-',
        lower: data?.lower ? data.lower : '-',
        upper: data?.upper ? data.upper : '-',
        forecast_standard_deviation: data?.fsd ? data.fsd : '-',
        confidence: data?.confidence ? data.confidence : '-',
      };

      state.queue.shift();

      state.estimatedData[locationType].push(processObj);
      if (state.queue.length === 0) {
        state.queueNumber = 0;
      }
    },
    updateConfig: (state, action) => {
      if (action.payload.name === 'apiKey') {
        state.config.apiKey.value = action.payload.value;
      } else if (action.payload.name === 'selectFile') {
        state.config.selectFile.value = action.payload.value;
      } else if (action.payload.name === 'shouldIncludeInvalidRow') {
        state.config.shouldIncludeInvalidRow.value = action.payload.value;
      }
      return state;
    },
    clearEstimated: (state) => {
      state.estimatedData[state.locationType] = [];
    },
    termsAccepted: (state, action) => {
      state.termsAccepted = action.payload;
      return state;
    },
    selectedTab: (state, action) => {
      state.selectedTab = action.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  selectedTab,
  termsAccepted,
  clearEstimated,
  updateConfig,
  updateLocationType,
  queryRejected,
  updateProcessed,
  getEstimate,
  updateQueue,
  updateQueueByForm,
  updateForm,
  resetForm,
  toggleUploadDialog,
} = ebbySlice.actions;

export default ebbySlice.reducer;
