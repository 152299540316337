import { Link, useLocation } from 'react-router-dom';
import Button from './Button';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleUploadDialog } from '../redux/ebbySlice';
type HeaderData = {
  username?: string;
  onLogout: () => void;
  isBuildium: boolean;
};

function Header(props: HeaderData) {
  const [selectedTab, setSelectedTab] = useState('ebby');
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    setSelectedTab((e) => {
      const path = location.pathname.replace('/', '');
      if (path === '') e = 'ebby';
      else e = path;
      return e;
    });
  }, [location]);

  return (
    <div className="header">
      <div className="logo"></div>
      <div className="tab-container">
        <span className={selectedTab === 'ebby' ? 'tab selected' : 'tab'}>
          <Link to="/">Ebby</Link>
        </span>
        <span className={selectedTab === 'indices' ? 'tab selected' : 'tab'}>
          <Link to="/indices">Indices</Link>
        </span>
        {/* {props.username && props.username.includes('beekin.co') ? (
        ) : null} */}

        {props.isBuildium ? (
          <span className={selectedTab === 'settings' ? 'tab selected' : 'tab'}>
            <Link to="/settings">Settings</Link>
          </span>
        ) : null}
      </div>
      <div className="config">
        {selectedTab === 'ebby' && (
          <Button
            className="upload-btn"
            callBack={() => dispatch(toggleUploadDialog(true))}
            name="Upload Excel"
            disabled={false}
            icon={<div className="icon-upload"></div>}
          />
        )}
        <div className="user-info" onClick={props.onLogout}>
          <p>{props.username}</p>
          <p>Sign out</p>
        </div>
      </div>
    </div>
  );
}

export default Header;
