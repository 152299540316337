import React from 'react';

type ButtonData = {
  name: string;
  callBack: any;
  disabled: boolean;
  className?: string;
  icon?: JSX.Element;
};

function Button(props: ButtonData) {
  if (!props.disabled) {
    return (
      <div
        className={`button-container frame ${props.className}`}
        onClick={props.callBack}>
        {props.icon ? props.icon : ''}
        <div className={`label ${props.className}`}>{props.name}</div>
      </div>
    );
  } else {
    return (
      <div className={`button-container frame disabled ${props.className}`}>
        {props.icon ? props.icon : ''}
        <div className={`label ${props.className}`}>{props.name}</div>
      </div>
    );
  }
}

export default Button;
