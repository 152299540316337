import React, { useEffect } from 'react';
import '../../styles/index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateBuildiumToken } from '../../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';

const SignUp = () => {
  const location = useLocation();
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    if (token) {
      dispatch(validateBuildiumToken(token));
    }
  }, [location.search, dispatch]);
  const navigate = useNavigate();

  useEffect(() => {
    let timeout: any = null;
    if (auth.userType === 'new' && auth.isAuthenticated) {
      timeout = setTimeout(() => {
        navigate('/settings');
      }, 5000);
    } else if (auth.userType?.includes('existing')) {
      timeout = setTimeout(() => {
        navigate('/login');
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [auth.isAuthenticated, navigate, auth.userType]);

  return (
    <div className="signup-buildium">
      <div className="full place-center">
        <div className="login-container place-center">
          <div className="logo"></div>
        </div>
        <div className="content">
          <div className="title">Welcome to Ebby</div>
          <div className="main">
            {auth.signup.loading === 'pending' ? (
              <div>
                <div className="low">Thanks for signing up.</div>
                <div className="low">
                  Please wait while we are setting up your account.
                </div>
                <div className="adjust"></div>
                <span className="loader"></span>
              </div>
            ) : null}
            {auth.signup.loading === 'succeeded' && auth.userType === 'new' ? (
              <div>
                <div className="high">Your account is now ready.</div>
                <div className="high">
                  Login instructions have been sent to you Email (
                  {auth.username})
                </div>
                <div>Redirecting to dashboard in few seconds.</div>
              </div>
            ) : null}
            {auth.signup.loading === 'succeeded' &&
            auth.userType?.includes('existing') ? (
              <div>
                <div className="high">
                  Account already exist with this email.
                </div>
                <br />
                <div className="high">
                  Please login to proceed. Redirecting to login page.
                </div>
              </div>
            ) : null}
            {auth.signup.loading === 'failed' ? (
              <div className="low">Something went wrong. Please try again.</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="help-container">
        <div className=" help">
          For further inquiries, you can reach out to us at{' '}
          <a href="mailto:care@beekin.co">care@beekin.co</a>.
        </div>
      </div>
    </div>
  );
};

export default SignUp;
