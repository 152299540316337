const properCase = (str: string): string => {
  let temp = str[0].toUpperCase() + str.substring(1);
  return temp;
};

const formatLabel = (label: string): string => {
  if (!label || label === '') {
    return label;
  }
  let returnLabel = '';

  let words = label.split('_');

  if (words.length > 0) {
    returnLabel = words.map(properCase).join(' ');
  } else {
    returnLabel = properCase(label);
  }

  return returnLabel;
};

export default formatLabel;
