import { useState } from 'react';
import Input from './Input';
import Select from './Select';
import Button from './Button';
import { toast } from 'react-toastify';
import { validateEntryObject } from '../excelValidator';
import { LocationType, propertyTypeMapping } from '../constants';
import {
  getEstimate,
  resetForm,
  updateForm,
  updateLocationType,
} from '../redux/ebbySlice';

type AddFormData = {
  template: Array<any>;
  dispatch: any;
  hasKey: boolean;
  locationType: string;
};

const templateToObject = (data: Array<any>): { [key: string]: any } => {
  const obj: { [key: string]: any } = {};
  for (let item of data) {
    obj[item.name] = item.value;
  }
  return obj;
};

function AddForm(props: AddFormData): JSX.Element {
  const [error, setError] = useState('');

  const handleChange = (key: string, value: string) => {
    setError('');
    props.dispatch(
      updateForm({ key, value, locationType: props.locationType })
    );
  };

  const formSubmit = () => {
    if (!props.hasKey) {
      toast('Please enter API key', {
        type: 'error',
      });
      return;
    }

    let formDataObj = templateToObject(props.template);
    const validationResult = validateEntryObject<typeof formDataObj>(
      formDataObj,
      props.locationType
    );

    if (!validationResult.isValid) {
      setError(validationResult.error_reason);
    } else {
      props.dispatch(resetForm());
      props.dispatch(
        getEstimate({ ...validationResult, locationType: props.locationType })
      );
    }
  };

  const toggleLocationType = () => {
    let newLocationType = LocationType.ADDRESS;
    if (props.locationType === LocationType.ADDRESS)
      newLocationType = LocationType.COORDINATES;
    else newLocationType = LocationType.ADDRESS;

    props.dispatch(updateLocationType({ newLocationType }));
  };

  const submitForm: any = (event: KeyboardEvent) => {
    if (event.key === 'Enter') formSubmit();
  };
  return (
    <div className="formContainer">
      <div className="addForm" onKeyUp={submitForm}>
        {props.template.map((item, index: number) => {
          if (item.type === 'text') {
            return (
              <div
                key={index}
                style={
                  item.width
                    ? { width: item.width, marginRight: '30px' }
                    : { marginRight: '30px' }
                }>
                <Input
                  name={item.name}
                  value={item.value}
                  placeholder={item.placeholder}
                  callBack={(value: any) =>
                    handleChange(item.name, value)
                  }></Input>
              </div>
            );
          }
          if (item.type === 'select') {
            return (
              <div
                key={index}
                style={
                  item.width
                    ? { width: item.width, marginRight: '30px' }
                    : { marginRight: '30px' }
                }>
                <Select
                  mappedLabels={propertyTypeMapping}
                  name={item.name}
                  value={item.value === '' ? '0' : item.value}
                  options={item.options}
                  callBack={(value: any) =>
                    handleChange(item.name, value)
                  }></Select>
              </div>
            );
          }
          return null;
        })}
        <div className="btn-add">
          <Button
            callBack={formSubmit}
            name="Get Estimate"
            disabled={false}></Button>
        </div>
        <div className="flex-break"></div>
        <p className="form-error">{error}</p>
      </div>
      <div>
        <span onClick={toggleLocationType}>
          Enter{' '}
          <strong>
            {props.locationType === LocationType.ADDRESS
              ? LocationType.COORDINATES
              : LocationType.ADDRESS}{' '}
          </strong>
          instead.
        </span>
      </div>
    </div>
  );
}

export default AddForm;
