import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { acceptTerms } from '../api';
import { termsAccepted } from '../redux/ebbySlice';

type TermsAndConditionsDialogProps = {
  dispatch: React.Dispatch<any>;
  userId: string;
  toast: any;
};

function TermsAndConditionsDialog(
  props: TermsAndConditionsDialogProps
): JSX.Element {
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleReadCheckChange = (event: React.BaseSyntheticEvent) => {
    console.log(event.target.value);
    if (event.target.checked === true) setBtnDisabled(false);
    else setBtnDisabled(true);
  };

  const handleTermsAgreement = () => {
    acceptTerms(props.userId)
      .then(() => props.dispatch(termsAccepted(true)))
      .catch((err) => {
        console.log(`${err.message}`);
        toast.error(
          'Something went wrong. Please connect with the support team.',
          {
            position: 'top-right',
          }
        );
      });
  };

  return (
    <div className="term-n-conditions">
      <div className="overlay" />
      <div className="modal-overlay ">
        <div className="modal">
          <div className="modal-header">
            <h2>Terms and Conditions</h2>
          </div>
          <div className="modal-body">
            <div className="content c7 doc-content">
              <p className="c0">
                <span className="c1">Terms and Conditions</span>
              </p>
              <p className="c0">
                <span className="c1">
                  These Terms and Conditions govern the use by any Client of
                  Beekin Inc (&quot;Client&quot;) and each of its employees,
                  agents, affiliates and representatives of the Corporate
                  Partner system database, model output and any other
                  information pertaining thereto (collectively, the
                  &quot;Database&quot;) provided by Beekin Inc
                  (&quot;Beekin&quot;).
                </span>
              </p>
              <p className="c0">
                <span className="c2">
                  Member Account, Password and Security
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  For users using the rental valuation database, the client will
                  receive a password and account designation upon purchase of
                  access to the Database. Client is responsible for maintaining
                  the confidentiality of the password and account, and is fully
                  responsible for all activities that occur under its password
                  or account. Client agrees to immediately notify Beekin of any
                  unauthorized use of its password or account or any other
                  breach of security, and will ensure that it exits from its
                  account at the end of each session. Beekin cannot and will not
                  be liable for any loss or damage arising from Client&#39;s
                  failure to comply with this provision.
                </span>
              </p>
              <p className="c0">
                <span className="c2">Representations and Warranties</span>
              </p>
              <p className="c0">
                <span className="c1">
                  Client represents and warrants to Beekin that (i) it has full
                  legal right, power and authority to agree to these Terms and
                  Conditions and carry out the transactions contemplated hereby;
                  and (ii) these Terms and Conditions have been reviewed by
                  Client and are the lawful, valid and legally binding
                  obligation of Client, enforceable in accordance with its
                  terms.
                </span>
              </p>
              <p className="c0">
                <span className="c2">Other Conduct</span>
              </p>
              <p className="c0">
                <span className="c1">
                  Client agrees to abide by the following rules for use of the
                  Database.
                </span>
              </p>
              <ul className="c4 lst-kix_4acad0y3aqq3-0 start">
                <li className="c3 li-bullet-0">
                  <span className="c1">
                    Client may not resell, redistribute, re-post, or place on
                    any online forum, or network, or in any database shared by
                    third parties, any data found on the Database, or charge
                    third parties for access to the Database.
                  </span>
                </li>
                <li className="c3 li-bullet-0">
                  <span className="c1">
                    The Database is to be accessed and used solely by Client and
                    those within Client authorized to engage in direct
                    employment recruitment on behalf of Client and who are
                    registered to use and access the Database.
                  </span>
                </li>
                <li className="c3 li-bullet-0">
                  <span className="c1">
                    The Database may be used solely for lawful purposes, and may
                    not be used in such a manner as to violate any applicable
                    law.
                  </span>
                </li>
                <li className="c3 li-bullet-0">
                  <span className="c1">
                    The Database may not be used for the purposes of destroying,
                    imitating, disrupting or interrupting any software, hardware
                    or any part of the Internet, with respect to Beekin or any
                    other party, including denial of service attacks, imposition
                    of an unreasonable or disproportionately large load on
                    infrastructure, or virus dissemination.
                  </span>
                </li>
                <li className="c3 li-bullet-0">
                  <span className="c1">
                    Client may not use any robot, spider or other automated
                    device to monitor or copy the web pages of the Database or
                    any information provided on the Database without the express
                    written permission of Beekin.
                  </span>
                </li>
              </ul>
              <p className="c0">
                <span className="c2">Future Use Of The Database</span>
              </p>
              <p className="c0">
                <span className="c8">Beekin</span>
                <span className="c1">
                  &nbsp;reserves the right to modify any features of the
                  Database at any time without advance notice. In the event
                  either Client or Beekin terminate any related Agreement
                  between them for any reason, Client remains liable for any
                  fees incurred before such termination is effective. BEEKIN
                  MAY, IN ITS SOLE DISCRETION, TERMINATE OR SUSPEND ACCESS TO
                  ALL OR PART OF THIS DATABASE FOR CLIENT&#39;S FAILURE TO
                  COMPLY WITH THESE TERMS AND CONDITIONS.
                </span>
              </p>
              <p className="c0">
                <span className="c2">Content</span>
              </p>
              <p className="c0">
                <span className="c8">Beekin</span>
                <span className="c1">
                  &nbsp;does not, and is not in a position to, review,
                  investigate, or confirm the accuracy of information on
                  Database. Beekin uses reasonable efforts to ensure that
                  information it provides on the Database will be accurate, but
                  it does not guarantee the accuracy, timeliness, completeness
                  or quality of any material that is found on, in connection
                  with, or posted to the Database. Further, Beekin, although it
                  may choose to do so, is under no obligation to record or
                  retain any of the material on or associated with the Database,
                  regardless of any requests to do so.
                </span>
              </p>
              <p className="c0">
                <span className="c2">Ownership</span>
              </p>
              <p className="c0">
                <span className="c1">
                  All materials on the Database that are created or provided by
                  Beekin, including text, graphics, logos, icons, and images,
                  are the property of Beekin or its content providers, and are
                  protected by United States and foreign intellectual property
                  laws. The compilation of all the content on this Database is
                  the exclusive property of Beekin and is also protected by
                  United States and foreign intellectual property laws. Client
                  may download, view, copy, and print the materials on this
                  Database for personal or internal business use only, provided
                  that it does not remove or alter any trademark, service mark,
                  or logo, or any copyright or other intellectual property
                  notices. Except as provided above, Client may not download,
                  view, copy, print, reproduce, distribute, republish, display,
                  post, transmit, or modify any material, or portion thereof,
                  located on the Database, or resell access to the Database, in
                  any form or by any means without the prior written consent of
                  Beekin. Beekin reserves the right to revoke any of the rights
                  granted in these Terms and Conditions at any time Client
                  violates these Terms and Conditions. Unauthorized use of any
                  material on the Database may violate copyright law, trademark
                  law, and other laws of the United States and other
                  jurisdictions. All rights not expressly granted in these Terms
                  and Conditions are reserved. The trademarks, service marks,
                  names and logos used on the Database are trademarks, service
                  marks, names and logos of Beekin or others.
                </span>
              </p>
              <p className="c0">
                <span className="c2">Equitable Relief</span>
              </p>
              <p className="c0">
                <span className="c1">
                  Client acknowledges and agrees that there can be no adequate
                  remedy at law to compensate Beekin for the breach of these
                  Terms and Conditions; that any such breach will result in
                  irreparable harm that would be difficult to measure; and,
                  therefore, that upon any such breach or threat thereof, Beekin
                  shall be entitled to seek injunctive and other appropriate
                  equitable relief from any court of competent jurisdiction
                  (without the necessity of proving actual damages or of posting
                  a bond), in addition to whatever remedies it may have at law,
                  under these Terms and Conditions, or otherwise.
                </span>
              </p>
              <p className="c0">
                <span className="c2">No Endorsements by Beekin</span>
              </p>
              <p className="c0">
                <span className="c1">
                  Nothing in the Database shall be considered an endorsement,
                  representation or warranty with respect to any location or
                  rental value, whether in regards to experience or otherwise.
                </span>
              </p>
              <p className="c0">
                <span className="c2">Disclaimer Of Consequential Damages</span>
              </p>
              <p className="c0">
                <span className="c1">
                  IN NO EVENT SHALL BEEKIN BE LIABLE FOR INDIRECT, INCIDENTAL,
                  CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR
                  IN CONNECTION WITH THESE TERMS AND CONDITIONS, INCLUDING,
                  WITHOUT LIMITATION, (a) BREACH OF ANY WARRANTY OR ANY OTHER
                  OBLIGATION IMPOSED ON GRADLEADERS HEREUNDER OR IN CONNECTION
                  HEREWITH; (b) THE USE OF OR INABILITY TO USE THE DATABASE; AND
                  (c) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE DATABASE
                  OR ELSEWHERE, OR CLIENT&#39;S RELIANCE ON THE SAME.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  &quot;Consequential damages&quot; include, without limitation,
                  loss of use, loss of customers, income or profit, damages or
                  losses resulting from claims of other persons against Client,
                  or damages or losses sustained as the result of any decision
                  regarding Client&#39;s investment in property whose rent value
                  was discovered on the Database. Beekin shall not be liable for
                  any failure to perform under these Terms and Conditions where
                  such failure results from any cause beyond the reasonable
                  control of Beekin, including, but not limited to, mechanical,
                  electronic, or communications failure or degradation.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
                  WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
                  INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, THOSE
                  PROVISIONS SET FORTH ABOVE MAY NOT APPLY TO CLIENT.
                </span>
              </p>
              <p className="c0">
                <span className="c2">Indemnity</span>
              </p>
              <p className="c0">
                <span className="c1">
                  Client agrees to indemnify and hold harmless Beekin, its
                  affiliates, successors, advisors and licensors, and their
                  respective directors, officers, managers, members,
                  shareholders, employees and agents, from and against all
                  losses, expenses, damages and costs, including reasonable
                  attorneys&#39; fees, resulting from its violation of these
                  Terms and Conditions, or the failure to fulfill any
                  obligations relating to its account incurred by Client or any
                  other person using Client&#39;s account, or for any third
                  party claims arising as a result of Client&#39;s use of the
                  Database or reliance upon any information found on the
                  Database. Beekin reserves the right to take over the exclusive
                  defense of any claim for which Beekin is entitled to
                  indemnification under this Section. In such an event, Client
                  shall provide Beekin with such cooperation as is reasonably
                  requested by Beekin.
                </span>
              </p>
              <p className="c0">
                <span className="c2">Governing Law</span>
              </p>
              <p className="c0">
                <span className="c1">
                  These Terms and Conditions shall be governed by and construed
                  under the laws of the State of Texas, without regard to its
                  conflicts of laws principles. Except as set forth in the
                  &quot;Equitable Relief&quot; provision of these Terms and
                  Conditions, the sole jurisdiction and venue of any action
                  related to these Terms and Conditions shall be the Texas state
                  courts and the United States federal courts in Houston, Texas
                  and Client agrees to submit to personal and exclusive
                  jurisdiction of these courts.
                </span>
              </p>
              <p className="c0">
                <span className="c2">General Provisions</span>
              </p>
              <p className="c0">
                <span className="c1">
                  All the terms of these Terms and Conditions shall apply to and
                  bind the assignees and successors in interest of Client and
                  Beekin. These Terms and Conditions are not assignable by
                  Client without Beekin&#39;s prior written consent. The waiver
                  by Beekin of any breach or default shall not be deemed to be a
                  waiver of any later breach or default. The exercise or failure
                  to exercise any remedy shall not preclude the exercise of that
                  remedy at another time or of any other remedy at any time. If
                  any provision or portion of these Terms and Conditions is held
                  to be invalid or unenforceable, the other provisions and
                  portions shall not be affected. The headings are used for the
                  convenience of the parties only and shall not affect the
                  construction or interpretation of these Terms and Conditions
                </span>
              </p>
              <p className="c6">
                <span className="c5"></span>
              </p>
            </div>
          </div>
          <span className="checkbox">
            <input type="checkbox" onChange={handleReadCheckChange} />I have
            read and agree with the above terms and conditions.
          </span>

          <button
            type="button"
            title="modal_close"
            className={`btn ${btnDisabled ? 'disabled' : ''}`}
            disabled={btnDisabled}
            onClick={handleTermsAgreement}>
            Agree
          </button>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditionsDialog;
