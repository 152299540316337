import React from 'react';

const icons: any = {
  'upload-icon': <div className="icon-upload"></div>,
};
type SelectFileData = {
  onChange: (target: React.BaseSyntheticEvent) => void;
  onClick?: (target: React.BaseSyntheticEvent) => void;
  icon?: string;
};
function SelectFile(props: SelectFileData) {
  return (
    <div className="selectFile-container" onClick={props.onClick}>
      <div className="hiddenText">Upload Excel</div>{' '}
      {props.icon ? icons[props.icon] : ''}{' '}
      <input
        title="upload_excel"
        type="file"
        onChange={props.onChange}
        className="custom-file-input "
      ></input>
    </div>
  );
}

export default SelectFile;
