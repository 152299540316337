// @ts-nocheck
import React from 'react';

import 'leaflet/dist/leaflet.css';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';

type BarChartProps = {
  data: any;
  geography?: string;
  location: string;
  measure: string;
  selectedDate: string;
};
const BarChartContainer = (props: BarChartProps) => {
  if (!props.data) return null;

  const chartData = props.data.barChartData[props.location];

  if (!chartData) {
    return <div>Data not available</div>;
  }
  const minValue = Math.round(Math.min(...chartData.data.map((d) => d.value)));
  const maxValue = Math.round(Math.max(...chartData.data.map((d) => d.value)));

  return (
    <ResponsiveContainer width="90%" height="85%">
      <BarChart
        width={500}
        height={300}
        data={chartData.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis domain={[minValue - 2, maxValue + 2]} />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="value"
          fill="#3b71c9"
          shape={<Rectangle />}
          name={props.measure}>
          {chartData.data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.value >= 0 ? '#3b71c9' : '#ba2a0d'}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartContainer;
