import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { getSettings, updateSettings } from '../../redux/settingsSlice';
import AppContainer from '../../Components/AppContainer';
import '../../styles/index.scss';
import DialogWindow from './DialogWindow';
import Loader from '../../Components/Loader';

const Settings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const settings = useSelector((state: RootState) => state.settings);
  const [enableEbby, setEnableEbby] = useState(settings.buildium.enableEbby);
  const [selectedDay, setSelectedDay] = useState(settings.buildium.selectedDay);
  const [selectedTime, setSelectedTime] = useState(
    settings.buildium.selectedTime
  );

  const [isSaveDialogVisible, setIsSaveDialogVisible] = useState(false);
  useEffect(() => {
    dispatch(getSettings(null));
  }, [dispatch]);

  useEffect(() => {
    setEnableEbby(settings.buildium.enableEbby);
    setSelectedDay(settings.buildium.selectedDay);
    setSelectedTime(settings.buildium.selectedTime);
  }, [settings.buildium]);

  const handleSave = (event: BaseSyntheticEvent) => {
    if (enableEbby && settings.buildium.isFirst) {
      setIsSaveDialogVisible(!isSaveDialogVisible);
    } else {
      dispatch(
        updateSettings({
          enableEbby,
          selectedDay,
          selectedTime,
        })
      );
    }
  };
  const handleEnableUpdate = (value: boolean) => {
    setEnableEbby(value);
  };
  const handleDayUpdate = (event: BaseSyntheticEvent) => {
    setSelectedDay(event.target.value);
  };

  const handleSaveWithUpdate = (updateImmediately: boolean) => {
    dispatch(
      updateSettings({
        enableEbby,
        selectedDay,
        selectedTime,
        updateImmediately,
      })
    );
    setIsSaveDialogVisible(!isSaveDialogVisible);
  };

  const handleTimeUpdate = (event: BaseSyntheticEvent) => {
    setSelectedTime(event.target.value);
  };

  function ordinal_suffix_of(i: number) {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + 'st';
    }
    if (j === 2 && k !== 12) {
      return i + 'nd';
    }
    if (j === 3 && k !== 13) {
      return i + 'rd';
    }
    return i + 'th';
  }
  return (
    <div className="App">
      <AppContainer>
        {isSaveDialogVisible ? (
          <DialogWindow
            question="Do you want to update market rent immediately after saving?"
            callBack={handleSaveWithUpdate}></DialogWindow>
        ) : (
          ''
        )}
        {settings.loading === 'pending' && <Loader />}
        <div className="place-center">
          <div className="settings">
            <h2>Preferences</h2>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span className="settings-label">
                        Enable Market rent update
                      </span>
                    </td>
                    <td>
                      <input
                        id="ebby_enable"
                        name="ebby_enable"
                        type="radio"
                        onChange={() => handleEnableUpdate(true)}
                        checked={enableEbby}
                      />
                      <label htmlFor="ebby_enable">Enable</label>
                      <input
                        id="ebby_disable"
                        name="ebby_enable"
                        type="radio"
                        onChange={() => handleEnableUpdate(false)}
                        checked={!enableEbby}
                      />
                      <label htmlFor="ebby_disable">Disable</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="settings-label">
                        Update Market rent on
                      </span>
                    </td>
                    <td>
                      <div>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                {/* <input
                                  id="daily"
                                  name="ebby_feq"
                                  type="radio"
                                 
                                  checked={true}
                                /> */}
                                <p className="settings-desc">
                                  <select onChange={handleDayUpdate}>
                                    {[
                                      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                      14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                                      24, 25, 26, 27, 28, 29, 30, 31,
                                    ].map((date) => {
                                      return (
                                        <option
                                          value={date}
                                          selected={date === selectedDay}>
                                          {date}
                                        </option>
                                      );
                                    })}
                                  </select>{' '}
                                  day of the month at{' '}
                                  {/* <TimePicker
                                    onChange={handleTimeUpdate}
                                    value={selectedTime}
                                  /> */}
                                  <input
                                    aria-label="Time"
                                    type="time"
                                    value={selectedTime}
                                    onChange={handleTimeUpdate}
                                  />
                                </p>
                                <p>
                                  Update will happen on{' '}
                                  {ordinal_suffix_of(selectedDay)} of every
                                  month at {selectedTime} {'EST. '}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="place-center">
                <button className="button-3" onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
};

export default Settings;
