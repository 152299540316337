import React from 'react';
import * as xlsx from 'xlsx';
import Button from './Button';
import { priceFormatter } from './../utils/priceFormatter';
import { LocationType, TableColumns } from '../constants';
type TableData = {
  estimatedData: Array<any>;
  locationType: string;
  shouldIncludeInvalidRow: boolean;
  callback: (value: boolean) => void;
  onClear: (value: boolean) => void;
};

function Table(props: TableData) {
  const downloadEstimates = () => {
    if (props.estimatedData.length > 0) {
      let estimatedData: any = [];
      props.estimatedData.forEach((item: any) => {
        const { locationType, ...rest } = item;
        let newItem = null;
        if (locationType === LocationType.COORDINATES) {
          const { geolocation, ...rest2 } = rest;
          newItem = { ...rest2 };
        } else {
          newItem = { ...rest };
        }

        if (newItem.upper !== '-') {
          newItem.upper = priceFormatter(newItem.upper);
        }
        if (newItem.lower !== '-') {
          newItem.lower = priceFormatter(newItem.lower);
        }
        if (newItem.estimated_rent !== '-') {
          newItem.estimated_rent = priceFormatter(newItem.estimated_rent);
        }
        estimatedData.push(newItem);
      });
      let ws = xlsx.utils.json_to_sheet(estimatedData);
      let wb = xlsx.utils.book_new();
      let date = new Date();
      xlsx.utils.book_append_sheet(wb, ws);

      xlsx.writeFile(
        wb,
        `rent_avm_estimates_${date.getFullYear()}_${date.getMonth()}_${date.getDay()}_${date.getMinutes()}.xlsx`
      );
    }
  };
  const locationType = props.locationType;
  let objectKeys =
    props.estimatedData.length > 0
      ? TableColumns[locationType].filter((elem) =>
          Object.keys(props.estimatedData[0]).includes(elem)
        )
      : [];

  let objectValues: any = [];

  props.estimatedData.forEach((item: any) => {
    let temp: any = [];
    objectKeys.forEach((key) => {
      temp.push(item[key]);
    });
    objectValues.push(temp);
  });

  let boldCols: any = [];
  let emphasized: any = [];

  return (
    <div className="table">
      {props.estimatedData.length > 0 && (
        <div className="table-options">
          <div className="download-estimates">
            <Button
              //   icon={<div className="icon-download"></div>}
              callBack={downloadEstimates}
              disabled={props.estimatedData.length > 0 ? false : true}
              name="Download Results"></Button>
          </div>
          <div className="download-estimates">
            <Button
              //   icon={<div className="icon-download"></div>}
              callBack={props.onClear}
              disabled={props.estimatedData.length > 0 ? false : true}
              name="Clear Table"></Button>
          </div>
          <label>
            <input
              type="checkbox"
              checked={props.shouldIncludeInvalidRow}
              onChange={(e) => props.callback(e.target.checked)}
            />{' '}
            include error items
          </label>
        </div>
      )}
      {props.estimatedData.length > 0 && (
        <>
          <table>
            <thead>
              <tr className="titles">
                {objectKeys.map((item: string, index: number) => {
                  if (item === 'upper' || item === 'lower') {
                    boldCols.push(index);
                  }
                  if (item === 'estimated_rent') {
                    emphasized.push(index);
                  }
                  item = item[0].toUpperCase() + item.substring(1);
                  return (
                    <th key={index}>
                      <div className="value">{item.replace(/_/g, ' ')}</div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {objectValues.map((item: any, index: number) => {
                return (
                  <tr key={index} className="data">
                    {Object.values(item).map((subitem: any, index2: number) => {
                      let extendedClass = '';
                      if (boldCols.includes(index2)) {
                        extendedClass = 'bold';
                      }
                      if (emphasized.includes(index2)) {
                        extendedClass = 'bold-green';
                      }

                      return (
                        <td key={index2}>
                          <div className={'value ' + extendedClass}>
                            {extendedClass && subitem !== '-'
                              ? `$${priceFormatter(subitem)}`
                              : subitem}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default Table;
