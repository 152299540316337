import React from 'react';
import formatLabel from '../utils/labelFormatter';

type InputData = {
  name: string;
  value: any;
  callBack: any;
  placeholder?: string;
  password?: boolean;
};

function Input(props: InputData) {
  const callBack = (response: React.BaseSyntheticEvent) => {
    props.callBack(response.target.value);
  };
  return (
    <div className="input-container">
      <div className="label">{formatLabel(props.name)}</div>
      <div className="value frame">
        <input
          id={props.name}
          type={props.password ? 'password' : 'text'}
          title={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={callBack}
        />
      </div>
    </div>
  );
}

export default Input;
