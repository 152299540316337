import React from "react";

type ProgressData = {
    value: number;
}

function ProgressBar(props: ProgressData) {


    return (
        <div className="progressBox"><div className="progress-container"><div className="progress-value" style={{width: props.value + '%'}}></div> </div></div>
    );
}

export default ProgressBar;