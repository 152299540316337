import React from 'react';
import formatLabel from '../utils/labelFormatter';

type SelectData = {
	name: string;
	value: any;
	callBack: any;
	options: any;
	mappedLabels?: any;
};

function Select(props: SelectData) {
	// const callBack = (response: React.BaseSyntheticEvent) => {
	//     props.callBack(response.target.value)
	// };

	const [toggleOptions, setToggleOptions] = React.useState(false);
	return (
		<div
			className="select-container"
			onClick={() => setToggleOptions(!toggleOptions)}
		>
			<div className="label">{formatLabel(props.name)}</div>
			<div className="value frame">
				{props.mappedLabels ? props.mappedLabels[props.value] : props.value}
				<div
					className="icon-select"
					onClick={() => setToggleOptions(!toggleOptions)}
				></div>
			</div>
			{toggleOptions ? (
				<div className="options frame">
					{props.options.map((item: string, index: number) => {
						return (
							<div
								className="option"
								key={index}
								onClick={() => {
									props.callBack(item);
									setToggleOptions(!toggleOptions);
								}}
							>
								{props.mappedLabels ? props.mappedLabels[item] : item}
							</div>
						);
					})}
				</div>
			) : (
				''
			)}
		</div>
	);
}

export default Select;
