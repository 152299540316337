import { useEffect } from 'react';
import * as xlsx from 'xlsx';
import { toast } from 'react-toastify';
import ProgressBar from '../../Components/ProgressBar';
import AddForm from '../../Components/AddForm';
import Table from '../../Components/Table';
import UploadExcelPopup from '../../Components/UploadExcelPopup';

import { rentEstimateV2, getUser } from '../../api';
import { validateEntryData, validateExcelColumns } from '../../excelValidator';

import '../../styles/index.scss';
import TermsAndConditionsDialog from '../../Components/TermsAndConditionsDialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../../store';
import { logout, updateApiKey } from '../../redux/authSlice';
import {
  termsAccepted,
  updateConfig,
  queryRejected,
  updateProcessed,
  updateQueue,
  clearEstimated,
  toggleUploadDialog,
} from '../../redux/ebbySlice';
import AppContainer from '../../Components/AppContainer';

function Dashboard() {
  const { userId, apiKey, apiKeyLoading } = useSelector(
    (state: RootState) => state.auth
  );
  const state = useSelector((state: RootState) => state.ebby);
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    console.log(`calling update key`);
    if (!apiKeyLoading && !apiKey) dispatch(updateApiKey());
  }, [apiKey, dispatch, apiKeyLoading]);

  useEffect(() => {
    if (userId) {
      getUser(userId)
        .then((res) => {
          dispatch(termsAccepted(res.data.TermsAccepted));
        })
        .catch((err) => {
          if (err.response.status === 401) dispatch(logout());
        });
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (state.queue.length > 0) {
      const firstItemInQueue = state.queue[0] as any;
      const queryPromise = new Promise((resolve) => {
        if (firstItemInQueue.isValid === false) {
          resolve({ queue: firstItemInQueue, data: null });
          return;
        }

        rentEstimateV2(firstItemInQueue, apiKey!)
          .then((res) => {
            resolve({ queue: firstItemInQueue, data: res.data });
          })
          .catch((err) => {
            if (err.response && err.response.status === 403) {
              dispatch(queryRejected({}));
              toast.info(
                'The API Key is currently unavailable. If this is a newly created account, please wait a few moments before attempting again. Otherwise, please contact our support team for assistance.',
                {
                  position: 'top-center',
                  hideProgressBar: true,
                }
              );
            } else {
              resolve({
                queue: {
                  ...firstItemInQueue,
                  isValid: false,
                  error_reason: err.response.data,
                },
                data: null,
              });
            }
          });
      });

      queryPromise
        .then((response) => {
          dispatch(
            updateProcessed({
              response: response,
              locationType: firstItemInQueue.locationType,
            })
          );
        })
        .catch((err) => {
          dispatch(queryRejected({}));
          toast(String(JSON.stringify(err.response.data)));
        });
    }
  }, [state, dispatch, apiKey]);

  useEffect(() => {
    const mapOnlyRequiredKeys = (
      data: Array<any>
    ): Array<{ [key: string]: any }> | null => {
      const cols = state.templates[state.locationType].map(
        (el: any) => el.name
      );

      const columnValidationResult = validateExcelColumns<(typeof data)[0]>(
        data,
        state.locationType
      );

      if (columnValidationResult) {
        toast.error(
          'Missing column found in excel. \n' +
            (columnValidationResult as string[]).join(', ')
        );
        return null;
      }

      const mappedData = data.map((el: any) => {
        const obj: { [key: string]: any } = {};
        for (let col of cols) {
          if (col in el) obj[col] = el[col];
        }
        return obj;
      });

      return mappedData;
    };

    const uploadFile = (target: React.BaseSyntheticEvent) => {
      let data = new FormData();
      data.append('file', target.target.files[0]);

      if (target.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target!.result;
          const workbook = xlsx.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);

          const mappedData = mapOnlyRequiredKeys(json);

          if (mappedData) {
            const validationResult = validateEntryData<(typeof mappedData)[0]>(
              mappedData,
              state.locationType
            );

            if (validationResult.length > 0) {
              if (validationResult.some((x) => !x.isValid))
                toast.error('Invalid row(s) found in excel.');
              dispatch(updateQueue({ validatedForm: validationResult }));
            }
          }
        };
        reader.readAsArrayBuffer(target.target.files[0]);
      }
    };

    if (state.config.selectFile.value !== null) {
      uploadFile(state.config.selectFile.value);
    }
  }, [
    state.config.selectFile.value,
    state.locationType,
    state.templates,
    dispatch,
  ]);

  let progressValue = 100;

  if (state.queueNumber > 0) {
    progressValue =
      ((state.queueNumber - state.queue.length) / state.queueNumber) * 100;
  }

  const mappedEstimatedData = state.estimatedData[state.locationType]
    .filter((x: any) => {
      return x.isValid === state.config.shouldIncludeInvalidRow.value
        ? x.isValid
        : true;
    })
    .map((x: any) => {
      const { isValid, error_reason, ...rest } = x;
      let obj = { ...rest };
      if (state.config.shouldIncludeInvalidRow.value)
        obj['error_reason'] = error_reason;
      return obj;
    });

  const handleClear = () => {
    dispatch(clearEstimated());
  };
  return (
    <div className="App">
      {!state.termsAccepted ? (
        <TermsAndConditionsDialog
          dispatch={dispatch}
          userId={userId!}
          toast={toast}
        />
      ) : null}

      <AppContainer>
        <>
          <div className="sectionContainer">
            <div className="title">Estimates</div>
          </div>
          <AddForm
            template={state.templates[state.locationType]}
            dispatch={dispatch}
            hasKey={apiKey ? true : false}
            locationType={state.locationType}
          />

          <div className="file-container">
            {progressValue !== 100 ? (
              <ProgressBar value={progressValue}></ProgressBar>
            ) : (
              ''
            )}
          </div>
          {mappedEstimatedData.length > 0 && (
            <Table
              estimatedData={mappedEstimatedData}
              locationType={state.locationType}
              callback={(value) =>
                dispatch(
                  updateConfig({
                    name: 'shouldIncludeInvalidRow',
                    value: value,
                  })
                )
              }
              shouldIncludeInvalidRow={
                state.config.shouldIncludeInvalidRow.value
              }
              onClear={handleClear}
            />
          )}
          {state.openUploadDialog ? (
            <UploadExcelPopup
              closeModal={() => dispatch(toggleUploadDialog(false))}
              apiKey={apiKey}
              dispatch={dispatch}
              locationType={state.locationType}
            />
          ) : null}
        </>
      </AppContainer>
    </div>
  );
}

export default Dashboard;
