// @ts-nocheck
import { LocationType, ValidPropertyTypeValues } from './constants';

export type validationResult = {
  isValid: boolean;
  error_reason: string;
};

// Later can also implement Fluent Validation https://fluentvalidation-ts.alexpotter.dev/docs/overview.html

export function validateExcelColumns<T>(
  data: Array<T>,
  locationType: string
): Array<string> | boolean {
  const requiredColumns = ['sqft', 'beds', 'baths'];
  const missingColumns: boolean | string[] = [];

  const xlHeaders = Object.keys(data[0]);

  //validate location or address columns
  if (
    locationType === LocationType.COORDINATES &&
    !xlHeaders.includes('lat_long')
  ) {
    missingColumns.push('lat_long');
  } else if (
    locationType === LocationType.ADDRESS &&
    !xlHeaders.includes('address')
  ) {
    missingColumns.push('address');
  }

  for (const item of requiredColumns) {
    if (!xlHeaders.includes(item)) {
      missingColumns.push(item);
    }
  }

  if (missingColumns.length === 0) return false;

  return missingColumns;
}

const getMappedPropertyTypeValue = (propertyType: string | number) => {
  switch (propertyType) {
    case 0:
    case '0':
    case 'Townhouse':
      return 0;
    case 1:
    case '1':
    case 'Single Family':
      return 1;
    case 2:
    case '2':
    case 'Multifamily/Apartment':
      return 2;
    case 3:
    case '3':
    case 'Condo':
      return 3;

    default:
      break;
  }
};

export function validateEntryObject<T>(
  data: T,
  locationType: string
): T & validationResult {
  let isValid = true;
  let errorReason = '';
  let validPropertyType = null;
  for (const [key, value] of Object.entries(data)) {
    // Required Column check
    if (['beds', 'baths', 'sqft'].includes(key) && value === '') {
      isValid = false;
      errorReason += ` In column: ${key}, value is empty,`;
    }

    if (locationType === LocationType.COORDINATES) {
      if (key === 'lat_long') {
        const [lat, long] = String(value).split(',');

        if (
          Number(lat) < -90 ||
          Number(lat) > 90 ||
          Number(long) < -180 ||
          Number(long) > 180
        ) {
          isValid = false;
          errorReason += ` In column: lat_long, invalid co-ordinates,`;
        }
      }
    }

    // 'beds', 'baths', 'sqft' should only have numbers
    if (
      key !== 'address' &&
      key !== 'property_type' &&
      /[a-zA-Z]/g.test(value as string)
    ) {
      isValid = false;
      errorReason += ` In column: ${key}, value has other than numbers,`;
    }

    if (key === 'property_type') {
      if (!ValidPropertyTypeValues.includes(value as string | number)) {
        errorReason += ` In column: ${key}, un supported value present,`;
        isValid = false;
        validPropertyType = value;
      } else {
        validPropertyType = getMappedPropertyTypeValue(
          value as string | number
        );
      }
    }

    // 'beds', 'baths' should be Min 0 and Max 10
    if (
      ['beds', 'baths'].includes(key) &&
      (Number(value) < 0 || Number(value) > 10)
    ) {
      isValid = false;
      errorReason += ` In column: ${key}, value less 0 or greater than 10,`;
    }
  }
  return {
    ...data,
    isValid: isValid,
    error_reason: isValid ? '' : errorReason,
    property_type: validPropertyType,
  };
}

export function validateEntryData<T>(
  data: Array<T>,
  locationType: string
): Array<T & validationResult> {
  const result: Array<T & validationResult> = [];
  for (let i = 0; i < data.length; i++) {
    result.push({
      ...validateEntryObject<(typeof data)[0]>(data[i], locationType),
      locationType,
    });
  }

  return result;
}
