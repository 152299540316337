import { configureStore } from '@reduxjs/toolkit';
import authReducer from './redux/authSlice';
import settingsSlice from './redux/settingsSlice';
import ebbyReducer from './redux/ebbySlice';
// ...

export const store = configureStore({
  reducer: {
    auth: authReducer,
    settings: settingsSlice,
    ebby: ebbyReducer,
    // indices: Reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
